import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom'

import './scss/style.scss'
import HomePage from './views/pages/site/HomePage'
import SpinnerWidget from './views/widgets/SpinnerWidget'



const loading = (
  <SpinnerWidget />
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const UserLayout    = React.lazy(() => import('./layout/UserLayout'))
const RouteLayout   = React.lazy(() => import('./layout/RouteLayout'))

// Pages
const Login    = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404  = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500  = React.lazy(() => import('./views/pages/page500/Page500'))


class App extends Component {

  constructor(props) {
    super(props); 
  } 

  render() {

    //const location = useLocation();

    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/site"       name="Site"           element={<HomePage />} />
            <Route exact path="/login"      name="Login Page"     element={<Login />} />
            <Route path="/register/:origin" name="Register Page"  element={<Register />}  />
            
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            <Route path="*" name="User" element={<RouteLayout />} /> 

          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
