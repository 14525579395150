import React from 'react'
import { CSpinner } from "@coreui/react";

const SpinnerWidget = () => {
    return (
        <>
            <div className="text-center">
                <CSpinner color={types[random(0, types.length - 1)]} variant="grow" />
            </div>
        </>
    )
}

function random(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
}

const types = [
    'primary',
    'dark',
    'info',
    'danger',
    'success',
    'warning',
    'secondary'
]


export default SpinnerWidget;