
import React, { useEffect } from "react";


/**
 * Redirect para o site principal
 * 
 */
function HomePage() {

  useEffect(() => {
    window.location.replace('https://multisked.com.br')
  }, []);


}

export default HomePage
